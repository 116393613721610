<template>
  <div v-if="invoices">
    <ModalConfirmCredit
      v-if="showConfirmCredit"
      :showModal="showConfirmCredit"
      :invoice="selectedInvoice"
      :onClickCancel="hideModal"
    />

    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
      drawerSize="extra-large"
    >
      <template v-slot:content>
        <InvoiceDetail
          :invoiceId="selectedInvoiceId"
          v-if="showInvoiceDetail"
        />
      </template>
    </ui-component-drawer>

    <div v-if="invoices.length > 0">
      <input type="text" class="input" v-model="searchTerm" />
    </div>

    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th width="50">Status</th>
          <th width="100">Period</th>
          <th width="150">Invoice nr</th>
          <th v-if="locationId === 0">Location</th>
          <th width="50" class="has-text-right">Excl VAT</th>
          <th width="50" class="has-text-right">Incl VAT</th>
          <th width="150" class="has-text-right">Due on</th>
          <th width="150" class="has-text-right">Payment</th>
          <th width="100" class="has-text-right">&nbsp;</th>
        </tr>
      </thead>
      <transition-group
        name="staggered-fade"
        v-bind:css="false"
        v-on:before-enter="beforeEnter"
        v-on:enter="enter"
        tag="tbody"
      >
        <tr
          v-for="(invoice, index) in filteredInvoices"
          :key="'tr_' + index"
          :data-index="index"
        >
          <td>
            <span class="tags">
              <span v-if="invoice.StatusId === 1" class="tag is-success">{{
                getStatusName(invoice.StatusId)
              }}</span>
              <span v-if="invoice.StatusId === 2" class="tag is-link">{{
                getStatusName(invoice.StatusId)
              }}</span>
              <span v-if="invoice.StatusId === 3" class="tag is-cancel">{{
                getStatusName(invoice.StatusId)
              }}</span>
            </span>

            <span class="tags">
              <span v-if="invoice.ReminderStatusId === 1" class="tag is-warning"
                >1st reminder</span
              >
              <span v-if="invoice.ReminderStatusId === 2" class="tag is-warning"
                >2nd reminder</span
              >
              <span
                @click="unlockLocation(invoice)"
                v-if="invoice.LocationIsLocked"
                class="tag is-danger"
                >Locked</span
              >
            </span>
          </td>
          <td>
            <a @click="goToInvoice(invoice)"
              >{{ invoice.Month | getMonthName }} {{ invoice.Year }}</a
            >
          </td>
          <td>
            <a @click="goToInvoice(invoice)">{{
              invoice.Year + '-' + invoice.Month + '-' + invoice.Number
            }}</a>
          </td>
          <td v-if="locationId === 0">
            {{ invoice.LocationName }}
            <a
              v-if="locationId === 0"
              @click="goToLocation(invoice.LocationId)"
              class="is-small"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'share']" />
              </span>
            </a>
          </td>
          <td class="has-text-right">
            {{ invoice.TotalExclTax | toCurrency }}
          </td>
          <td class="has-text-right">
            {{ invoice.TotalInclTax | toCurrency }}
          </td>
          <td class="has-text-right">
            <span>{{ invoice.ExpirationDate | dateObjectIsoDateString }}</span>
          </td>
          <td class="has-text-right">
            <a
              v-if="invoice.PaymentStatusId === 3"
              @click="changePaymentStatus(invoice)"
              class="has-icon"
              title="Mark as paid"
            >
              <span class="icon">
                <font-awesome-icon
                  :icon="['fas', 'check']"
                  class="has-text-success"
                />
              </span>
              <span>Paid</span>
            </a>

            <a
              v-if="invoice.PaymentStatusId !== 3"
              @click="changePaymentStatus(invoice)"
              class="has-icon"
              title="Mark as paid"
            >
              <span class="icon">
                <font-awesome-icon
                  :icon="['fas', 'times']"
                  class="has-text-danger"
                />
              </span>
              <span>Not paid</span>
            </a>

            <span>
              <a
                v-if="
                  invoice.StatusId === 1 &&
                  invoice.PaymentStatusId !== 3 &&
                  invoice.ReminderStatusId === 0 &&
                  invoice.DueInDays < 0
                "
                @click="sendFirstReminder(invoice)"
                class="button is-small"
                :class="{
                  'is-loading':
                    selectedInvoiceId === invoice.Id && isSendingReminder,
                }"
                >1st reminder</a
              >
              <a
                v-if="
                  invoice.StatusId === 1 &&
                  invoice.PaymentStatusId !== 3 &&
                  invoice.ReminderStatusId === 1 &&
                  invoice.DueInDays < 0
                "
                @click="sendSecondReminder(invoice)"
                class="button is-small"
                :class="{
                  'is-loading':
                    selectedInvoiceId === invoice.Id && isSendingReminder,
                }"
                >2nd reminder</a
              >
              <a
                v-if="
                  invoice.StatusId === 1 &&
                  invoice.PaymentStatusId !== 3 &&
                  invoice.ReminderStatusId === 2 &&
                  !invoice.LocationIsLocked &&
                  invoice.DueInDays < 0
                "
                @click="lockLocation(invoice)"
                class="button is-small"
                :class="{
                  'is-loading': selectedInvoiceId === invoice.Id && isLocking,
                }"
                >Lock location</a
              >
            </span>
          </td>

          <td class="has-text-right">
            <div class="field is-grouped">
              <p class="control">
                <a
                  v-if="invoice.StatusId === 1"
                  @click.exact="setShowConfirmCredit(invoice)"
                  @click.ctrl="creditInvoiceData(invoice)"
                  class="has-icon"
                  title="Credit"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['far', 'shredder']" />
                  </span>
                </a>
              </p>
              <p class="control">
                <LinkDownload
                  type="feeinvoice"
                  :id="invoice.Id"
                  :hideLabel="true"
                  :feeInvoice="invoice"
                  iconCssClass="is-small"
                />
              </p>
            </div>
          </td>
        </tr>
        <tr v-if="invoices && invoices.length > 0" :key="'total'">
          <td :colspan="locationId === 0 ? 4 : 3">&nbsp;</td>
          <td class="has-text-right">
            <strong>{{ totalSum | toCurrency }}</strong>
          </td>
          <td colspan="3"></td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import staggeredAnimation from '@/mixins/staggeredAnimation'
import invoiceProvider from '@/providers/feeInvoice'
import locationProvider from '@/providers/location'

const InvoiceDetail = () => import('@/components/FeeInvoices/InvoiceDetail')
const LinkDownload = () => import('@/components/UI/LinkDownload.vue')

export default {
  components: {
    InvoiceDetail,
    LinkDownload,
    ModalConfirmCredit: () =>
      import('@/components/FeeInvoices/ModalConfirmCredit'),
  },

  mixins: [staggeredAnimation],

  props: {
    invoices: {
      type: Array,
      default: function () {},
    },

    locationId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      drawerTitle: '',
      showInvoiceDetail: false,
      showSideDrawer: false,
      selectedInvoice: null,
      selectedInvoiceId: 0,
      searchTerm: '',
      showConfirmCredit: false,
    }
  },

  computed: {
    //...mapState('invoiceStore', ['invoices']),

    filteredInvoices() {
      let invoices = this.invoices

      if (this.searchTerm.length > 0) {
        invoices = [
          ...invoices.filter((i) => {
            if (
              i.Code.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >
                -1 ||
              i.LocationName.toLowerCase().indexOf(
                this.searchTerm.toLowerCase()
              ) > -1
            ) {
              return true
            }
            return false
          }),
        ]
      }

      return invoices
    },

    totalSum() {
      let total = 0
      if (this.invoices && this.invoices.length > 0) {
        for (let i = 0; i <= this.invoices.length; i++) {
          let invoice = this.invoices[i]
          if (invoice && invoice.StatusId === 1) {
            total = total + invoice.TotalExclTax
          }
        }
      }
      return Math.round(total * 100) / 100
    },
  },

  created() {},

  methods: {
    ...mapActions('feeinvoiceStore', ['creditInvoice']),
    ...mapMutations('feeinvoiceStore', ['setInvoices']),

    goToInvoice(invoice) {
      this.showSideDrawer = true
      this.showInvoiceDetail = true
      this.selectedInvoiceId = invoice.Id
      this.drawerTitle = 'Invoice: ' + invoice.Year + '-' + invoice.Number
    },

    setShowConfirmCredit(invoice) {
      this.showConfirmCredit = true
      this.selectedInvoice = invoice
    },

    hideModal() {
      this.showConfirmCredit = false
      this.selectedInvoice = null
    },

    hideDrawer() {
      this.drawerTitle = ''
      this.showSideDrawer = false
      this.selectedInvoiceId = 0

      this.showInvoiceDetail = false
    },

    getStatusName(statusId) {
      let name = ''

      switch (statusId) {
        case 1:
          name = 'Final'
          break
        case 2:
          name = 'Frozen'
          break
        case 3:
          name = 'Credit'
          break
      }

      return name
    },

    creditInvoiceData(invoice) {
      this.creditInvoice(invoice.Id)
    },

    changePaymentStatus(invoice) {
      let paymentStatusId = 0

      if (invoice.PaymentStatusId === 0) {
        invoiceProvider.methods.markAsPaid(invoice.Id).then((response) => {
          if (response.status === 200) {
            let invoiceIndex = this.invoices.findIndex(
              (i) => i.Id === invoice.Id
            )
            if (invoiceIndex > -1) {
              Vue.set(this.invoices, invoiceIndex, response.data)

              this.setInvoices(this.invoices)
            }
          }
        })
      } else {
        invoiceProvider.methods
          .markAsOpen(invoice.Id, paymentStatusId)
          .then((response) => {
            if (response.status === 200) {
              let invoiceIndex = this.invoices.findIndex(
                (i) => i.Id === invoice.Id
              )
              if (invoiceIndex > -1) {
                Vue.set(this.invoices, invoiceIndex, response.data)

                this.setInvoices(this.invoices)
              }
            }
          })
      }
    },

    sendFirstReminder(invoice) {
      this.isSendingReminder = true

      invoiceProvider.methods
        .sendFirstReminder(invoice.Id)
        .then((response) => {
          if (response.status === 200) {
            let invoiceIndex = this.invoices.findIndex(
              (i) => i.Id === invoice.Id
            )
            if (invoiceIndex > -1) {
              Vue.set(this.invoices, invoiceIndex, response.data)

              this.setInvoices(this.invoices)
            }
          }
        })
        .then(() => {
          this.isSendingReminder = false
        })
    },

    sendSecondReminder(invoice) {
      this.isSendingReminder = true

      invoiceProvider.methods
        .sendSecondReminder(invoice.Id)
        .then((response) => {
          if (response.status === 200) {
            let invoiceIndex = this.invoices.findIndex(
              (i) => i.Id === invoice.Id
            )
            if (invoiceIndex > -1) {
              Vue.set(this.invoices, invoiceIndex, response.data)

              this.setInvoices(this.invoices)
            }
          }
        })
        .then(() => {
          this.isSendingReminder = false
        })
    },

    lockLocation(value) {
      this.selectedInvoiceId = value.Id
      this.isLocking = true

      locationProvider.methods
        .lockLocation(value.LocationId, 2)
        .then((response) => {
          value.LocationIsLocked = true
        })
        .then(() => {
          this.isLocking = false
          this.selectedInvoiceId = 0
        })
        .catch((err) => {})
    },

    unlockLocation(value) {
      this.isLocking = true

      locationProvider.methods
        .unlockLocation(value.LocationId)
        .then((response) => {
          value.LocationIsLocked = false
        })
        .then(() => {
          this.isLocking = false
        })
        .catch((err) => {})
    },

    goToLocation(locationId) {
      this.$router.push({ path: `/locations/${locationId}` })
    },
  },
}
</script>

<style></style>
